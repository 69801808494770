<template>
  <div class="relative bg-red-600 py-16 sm:py-12 text-whites">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-12 lg:items-center">
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <p class="font-light text-3xl">ESSAYEZ NOUS <span class="font-semibold">MAINTENANT!</span></p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <p class="font-light text-md">Ouvrez un compte gratuitement</p>
            <div class="mt-3 sm:flex sm:justify-center">
                <div class="rounded-md shadow">
                  <router-link to="/contact" class="w-auto flex items-center justify-center px-8 border border-transparent text-base font-medium rounded-md text-white bg-white text-red-500 hover:bg-white md:text-lg md:px-10">
                    COMMERCER
                  </router-link>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Barre'
}
</script>