<template>
  <div class="min-h-screen bg-white">
  <Header @loadContactForm="loadContactForm"></Header>
  <main>
    <PromoHero></PromoHero>
    <FeatureCalculator></FeatureCalculator>
    <!-- <FeatureLeft></FeatureLeft> -->
    <Process></Process>
    <Map></Map>
    <Testimonial></Testimonial>
    <Barre></Barre>
    <PageFooter></PageFooter>
  </main>
</div>
</template>

<script> 

// import Hero from './Hero.vue'
import Header from './Header.vue'
import PageFooter from './PageFooter.vue'
import Barre from './Barre.vue'
import Testimonial from './Testimonial.vue'
import Map from './Map.vue'
import Process from './Process.vue'
// import FeatureLeft from './FeatureLeft.vue'
import FeatureCalculator from './FeatureCalculator.vue'
import PromoHero from './PromoHero.vue'

export default {
  name: 'LandingNewCalculator',
  data() {
    return {
      response: null,
      Errors : [],
      contactForm: false,
      referral: '',
    }
  },
  components: {
     Header, PageFooter, Barre, Testimonial, Map, Process, FeatureCalculator, PromoHero, //FeatureLeft
  },
  methods: {
    setReferral: function(value) {
      if (window.localStorage.ref == undefined && value) {
        this.referral = value;
        window.localStorage.setItem('ref', value)
      } else {
        this.referral = window.localStorage.ref
      }
    }
  },
  mounted() {
    this.$ga.page('/');
    this.setReferral(new URL(location.href).searchParams.get('ref'));
  }
}
</script>

<style>
</style>