<template>
    <div>
      <!-- Hero card -->
      <div class="relative">
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="relative shadow-xl sm:rounded-tr-3xl sm:overflow-hidden">
            <div class="absolute inset-0">
              <img class="h-full w-full object-cover" src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100" alt="People working on laptops">
              <div class="absolute inset-0 bg-red-600" style="mix-blend-mode: multiply;"></div>
            </div>
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <div class="lg:grid lg:grid-cols-12 lg:gap-8">
                <div class="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                  <div>
                    <h1 class="mt-4 text-3xl tracking-tight font-light text-white sm:mt-5 sm:leading-none lg:mt-6 lg:text-4xl xl:text-5xl">
                      <span class="md:block">Obtenez une soumission pour vos envois et</span>
                      <span class="font-extrabold md:block"> économisez!</span>
                    </h1>
                    <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-base xl:text-lg border-b-2 pb-5">
                      Groupe Alliances mets à votre dispositions des prix compétitifs avec notre partenaire pour la livraison:
                    </p>
                    <img class="h-6 mt-6 inline" src="/images/Puro logo.png" alt="Tuple">
                  </div>
                </div>
                <div class="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                  <div class="bg-white sm:max-w-lg sm:w-full sm:mx-auto rounded-3xl sm:overflow-hidden">
                    <Calculateur :contactForm="contactForm"></Calculateur>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Logo cloud -->
      <div class="">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <p class="text-center text-3xl font-extrabold tracking-tight text-gray-900 mb-12">
            Voici quelques un de <span class="text-red-600">nos clients</span>
          </p>
          <div class="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4">
            <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img class="h-12" src="/images/logo 03.png" alt="StaticKit">
            </div>
            <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img class="h-12" src="/images/logo 01.png" alt="Tuple">
            </div>
            <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img class="h-12" src="/images/logo 02.png" alt="Mirage">
            </div>
            <div class="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1">
              <img class="h-12" src="/images/logo 04.png" alt="Transistor">
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Calculateur from './Calculateur.vue'

export default {
  name: 'Hero',
  components: {
    Calculateur
  },
  props: ['contactForm'],
  data() {
    return {
      response: null,
      Errors : []
    }
  }
}
</script>