<template>
  <section>
    <div class="relative bg-white py-12 sm:py-14">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-16 lg:items-start">
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-6 flex justify-center">
            <img class="inset-0 h-full w-full" src="/images/Map Canada.svg" alt="">
          </div>
        </div>
        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:pt-2">
          <!-- Content area -->
          <div class="pt-3 sm:pt-5 lg:pt-1">
            <div class="mt-6 text-gray-600 space-y-6 text-center">
              <p class="text-2xl font-thin">
                Peu importe où vous êtes au Canada, <span class="font-semibold">Groupe Alliances</span> et son service d’envoi dessert toutes les provinces Canadiennes.
              </p>
              <div class="flex flex-wrap items-stretch content-center justify-evenly">
                  <img class="self-center" src="/images/Logo Alberta.png" alt="">
                  <img class="self-center" src="/images/logo British Columbia.png" alt="">
                  <img class="self-center" src="/images/Logo Ile du prince edouard.png" alt="">
                  <img class="self-center" src="/images/Logo Labrador.png" alt="">
                  <img class="self-center" src="/images/Logo Nova Scotia.png" alt="">
                  <img class="self-center" src="/images/logo ontario.png" alt="">
                  <img class="self-center" src="/images/logo Saskatch.png" alt="">
                  <img class="self-center" src="/images/Manitoba.png" alt="">
                  <img class="self-center" src="/images/Logo Québec.png" alt="">
                  <img class="self-center" src="/images/New Brunswick.png" alt="">
              </div>
              <div class="mt-12 pt-5 sm:mt-8 sm:flex sm:justify-center">
                <div class="rounded-md shadow">
                  <router-link class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10" to="/contact">Ouvrez un compte gratuitement!</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Map'
}
</script>