<template>
  <div class="min-h-screen bg-white">
  <Header @loadContactForm="loadContactForm"></Header>
  <main>
    <div class="relative py-3 px-3 sm:max-w-2xl sm:mx-auto mt-12">
      <div class="rounded-3xl border-solid border-2 border-light-gray-100">
        <div class="relative px-4 py-12 xs:mx-4 mx-8 md:mx-0 sm:p-10 lg:w-auto">
          <div class="max-w-lg mx-auto">
            <div class="flex items-center space-x-5">
              <div class="h-14 w-14 bg-red-600 rounded-full flex flex-shrink-0 justify-center items-center text-white font-mono">
                    <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
              </div>
              <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
                <h2 class="leading-relaxed">Vous êtes intéressé à travailler avec nous ?</h2>
                <p class="text-sm text-gray-500 font-normal leading-relaxed">Remplissez le formulaire suivant pour nous contacter!</p>
              </div>
            </div>
            <Errors v-if="Errors.length > 0" :errors="Errors"></Errors>
            <Contact v-if="contact"></Contact>
          </div>
        </div>
      </div>
    </div>
  </main>
  <PageFooter></PageFooter>
</div>
</template>

<script> 

import Header from './Header.vue'
import Contact from './Contact.vue'
import PageFooter from './PageFooter.vue'

export default {
  name: 'ContactPage',
  data() {
    return {
      contact: true,
      response: null,
      Errors : [],
      contactForm: false,
    }
  },
  components: {
     Header, Contact, PageFooter
  },
  methods: {
    loadContactForm: function() {
      this.contactForm = true;
    }
  },
  mounted() {
    this.$ga.page('/');
  }
}
</script>

<style>

</style>
