<template>
  <div class="bg-red-100 rounded-2xl">
    <ul class="mt-5 p-5 list-disc list-inside text-xs text-red-700">
      <li v-for="error in Errors" v-bind:key="error.desc">
        <strong>{{error.AdditionalInformation}}</strong>: {{error.Description}}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Errors',
  props: {
    Errors: Array
  }
}
</script>