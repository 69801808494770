<template>
  <div class="divide-y divide-gray-200">
    <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7" v-if="!success">
      <div class="flex items-center space-x-6">
        <div class="flex flex-col flex-grow">
          <label class="block text-sm leading-loose font-medium text-gray-600">Nom</label>
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <input type="text" v-model="name"  class="placeholder-gray-300 pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Ex. Jean Tremblay">
            <div class="absolute left-3 top-2">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </div>
          </div>
        </div>

        <div class="flex flex-col flex-grow">
          <label class="block text-sm leading-loose font-medium text-gray-600">Compagnie</label>
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <input v-model="company" type="text" class="placeholder-gray-300 pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Ex. Groupe ABCD inc.">
            <div class="absolute left-3 top-2">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>  
            </div>
          </div>
        </div>
      </div>
      
      <div class="flex flex-col">
        <label for="price" class="block text-sm leading-loose font-medium text-gray-600">Courriel</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <input v-model="email" type="email" class="placeholder-gray-300 pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="ex. courriel@compangie.com">
            <div class="absolute left-3 top-2">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <label for="price" class="block text-sm leading-loose font-medium text-gray-600">Numéro de téléphone</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <input v-model="phone" v-mask="'(###) ###-####'" type="text" class="placeholder-gray-300 pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="ex. (514) 230-1869">
            <div class="absolute left-3 top-2">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <label for="price" class="block text-sm leading-loose font-medium text-gray-600">Commentaires</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <textarea v-model="comments" class="placeholder-gray-300 pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder=""></textarea>
            <div class="absolute left-3 top-2">
                <!-- <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                </svg>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="bg-red-100 rounded-2xl my-4" v-if="errors.length != 0">
        <ul class="p-5 list-disc list-inside text-xs text-red-700">
            <li v-for="error in errors" v-bind:key="error">
                <strong>{{error}}</strong>
            </li>
        </ul>
    </div>

    <div class="pt-4 flex items-center space-x-4" v-if="!success">
        <!-- <button class="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none" @click="clear">
          <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Effacer
        </button> -->
        <button class="bg-green-600 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none" @click="submit">
          <svg v-if="spinning" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <svg v-if="!spinning" class="w-6 h-6 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" /></svg> 
          Envoyer
        </button>
    </div>

    <div class="bg-green-100 rounded-2xl mt-12 text-center" v-if="success">
        <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3438033&conversionId=4080268&fmt=gif" />
        <p class="p-5 text-lg text-green-700">
            <strong>Merci! </strong> <br><br> Nous avons bien reçu votre message. Un membre de notre équipe vous contactera dans les meilleurs délais!
        </p>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: 'Contact',
  data: function () {
    return {
      spinning: false,
      success: false,
      referral: '',
      name: '',
      company: '',
      email: '',
      phone: '',
      comments: '',
      errors: [],
    }
  },
  mounted() {
    this.setReferral(new URL(location.href).searchParams.get('ref'));
  },
  methods: {
    setReferral: function(value) {
      if (window.localStorage.ref == undefined && value) {
        this.referral = value;
        window.localStorage.setItem('ref', value)
      } else {
        this.referral = window.localStorage.ref
        this.coupon = window.localStorage.coupon
      }
    },
    clear: function() {
      this.name = '';
      this.company = '';
      this.email = '';
      this.phone = '';
      this.send_type = '';
      this.comments = '';
      this.referral = '';
    },
    submit: function() {
      this.errors = [];

      if(this.name == '') {
          this.errors.push('Le champs nom est obligatoire!');
      }
      if(this.company == '') {
          this.errors.push('Le champs compagnie est obligatoire!');
      }
      if(this.email == '') {
          this.errors.push('Le champs courriel est obligatoire!');
      }
      if(this.phone == '') {
          this.errors.push('Le champs téléphone est obligatoire!');
      }

      if(this.phone != '' && this.name != '' && this.company != '' && this.phone != '') {
        this.spinning = true
        
        axios
        .post('/php/contact.php', {
          name: this.name,
          company: this.company,
          email: this.email,
          phone: this.phone,
          comments: this.comments,
          ref: this.referral,
          coupon: this.coupon
        })
        .then( response => {
          if (response.data.Error == undefined) {
            this.clear();
          } 
          this.clear();
          this.$ga.event('Quote', 'contact', 'Contact soumis')
          this.success = true;
          this.spinning = false
        })
      }
    }
  }
}
</script>
