<template>
  <div class="min-h-screen bg-white">
  <Header @loadContactForm="loadContactForm"></Header>
  <main>
    <Hero :contactForm="contactForm"></Hero>
    <!-- Alternating Feature Sections -->
    <div class="relative pt-16 pb-32 px-12 overflow-hidden">
      <div class="relative">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div class="justify-left mt-6">
                <img class="h-12" src="/images/logo_cercle.png" alt="Tuple">
              </div>
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  Aider les entreprises à travers le Québec <span class="text-red-600">à économiser</span>
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Groupe Alliances est un réseau privé et sélectif destiné à mettre en relation ses membres dans un contexte d’affaires et de centres d'intérêt partagés afin de vous permettre d’économiser et de profiter d’un large éventail de services professionnels et être plus efficaces, et ce, à moindre coût.
                </p>
                <p class="mt-4 text-lg text-gray-500">
                  Depuis 2014, nous aidons des PMEs au Québec à économiser en regroupant et partageant nos forces.
                </p>
                <div class="mt-6">
                  <a href="#" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-red-700 to-red-600 hover:from-purple-700 hover:to-red-700">
                    Obtenir une soumission
                  </a>
                </div>
              </div>
            </div>
            <div class="mt-8 border-t border-gray-200 pt-6">
              <blockquote>
                <div>
                  <p class="text-base text-gray-500">
                    &ldquo;Groupe Alliances nous a aidé à réduire nos frais d'expéditions de moitié! Ç'a pris quelques heures pour être opérationnels et commencer à faire des économies&rdquo;
                  </p>
                </div>
                <footer class="mt-3">
                  <div class="flex items-center space-x-3">
                    <div class="flex-shrink-0">
                      <img class="h-6 w-6 rounded-full bg-red-200" src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80" alt="">
                    </div>
                    <div class="italic text-sm font-medium text-gray-400">
                      Louise Robitaille, Gestion des opérations
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0">
            <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img class="w-full lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="images/Background img 1.png" alt="Inbox user interface">
            </div>
          </div>
        </div>
      </div>
      <div class="mt-24">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span class="h-12 w-12 rounded-md flex items-center justify-center bg-gradient-to-r from-red-700 to-red-600">
                  <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
                  </svg>
                </span>
              </div>
              <div class="mt-6">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                  La vraie question est: comment pouvons-nous <span class="text-red-600">vous aider ?</span> 
                </h2>
                <p class="mt-4 text-lg text-gray-500">
                  Discutez avec nos experts au sujet de vos besoins. Vous serez surpris des économies potentielles à faire affaires avec Groupe Alliances
                </p>
                <ul class="list-disc mt-5 list-inside text-lg text-gray-500">
                  <li><a href="malto:service@groupealliances.com">service@groupealliances.com</a></li>
                  <li><a href="tel:+18778444756">+1 (877) 844-4756</a></li>
                </ul>
                <div class="mt-6">
                  <a href="#" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gradient-to-r from-red-700 to-red-600 hover:from-purple-700 hover:to-red-700">
                    Obtenir une soumission
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img class="w-full lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="images/Background img 2.png" alt="Groupe alliances">
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <PageFooter></PageFooter>
</div>
</template>

<script> 

import Hero from './Hero.vue'
import Header from './Header.vue'
import PageFooter from './PageFooter.vue'

export default {
  name: 'App',
  data() {
    return {
      response: null,
      Errors : [],
      contactForm: false,
    }
  },
  components: {
     Header, Hero, PageFooter
  },
  methods: {
    loadContactForm: function() {
      this.contactForm = true;
    }
  },
  mounted() {
    this.$ga.page('/');
  }
}
</script>

<style>

</style>
