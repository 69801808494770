<template>
  <div class="relative bg-gray-100 py-16 sm:py-18 text-gray-600 mb-10">
      <div class="text-center my-12">
        <h3 class="text-3xl font-light">Prêt à commencer à <span class="font-semibold">économiser</span> ?</h3>
      </div>
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:gap-12 lg:items-start">
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 1.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Remplissez le formulaire, ouvrez un compte gratuitement et sans engagement avec Groupe Alliances en seulement 24 heures</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 2.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Effectuer vos envois de colis à partir du Site web de Purolator en utilisant les codes d’accès fourni par Groupe Alliances</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 3.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Purolator s’occupe de tout. Ils viennent ramasser vos colis à la porte et les livrent à destination dans les délais garantis. Service Express disponible partout au Canada</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon facture.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Recevez votre facture du Groupe Alliances détaillant chacun de vos envois et économiser. Vous avez le choix de payer par virement, chèque ou carte de crédit</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 4.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Sauvez du temps grâce à notre service. Si vous avez des questions ou remarqué une anomalie sur votre facture, contactez nous pour recevoir une assistance immédiate et personnalisée</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Process'
}
</script>