<template>
  <!-- <div class="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12" id="app"> -->
  <div class="relative py-3 sm:max-w-xl sm:mx-auto">
    <div class="relative px-4 py-12 bg-white xs:mx-4 mx-8 md:mx-0 sm:p-10 lg:w-144">
      <div class="max-w-lg mx-auto">
        <div class="flex items-center space-x-5">
          <div class="h-14 w-14 bg-red-600 rounded-full flex flex-shrink-0 justify-center items-center text-white font-mono">
                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
          </div>
          <div class="block pl-2 font-semibold text-xl self-start text-gray-700">
            <h2 class="leading-relaxed">Obtenez une soumission rapide</h2>
            <p class="text-sm text-gray-500 font-normal leading-relaxed">Comparez les tarifs d'expédition et économisez!</p>
          </div>
        </div>
        <EstimateForm v-if="!response" @response="loadResponse"></EstimateForm>
        <Errors v-if="Errors.length > 0" :errors="Errors"></Errors>
        <Results v-if="response" @clear="clear" :results="response" @loadContact="loadContact"></Results>
      </div>
    </div>
  </div>
<!-- </div> -->
</template>

<script>
import EstimateForm from './EstimateForm.vue'
import Errors from './Errors.vue'
import Results from './Results.vue'

export default {
  name: 'Calculateur',
  data() {
    return {
      response: null,
      contact: null,
      Errors : []
    }
  },
  props: ['contactForm'],
  components: {
    EstimateForm, Errors, Results
  },
  methods: {
    clear: function() {
      this.response = null;
    },
    loadResponse: function(response) {
      if (response.Error != undefined) {
        this.$ga.event('Quote', 'error', 'Erreur de contenu')
        if(Array.isArray(response.Error)) {
          this.Errors = response.Error
        } else {
          this.Errors = [response.Error]
        } 
      } else {
        this.$ga.event('Quote', 'success', 'Soumission reçue')
        this.response = response;
        this.Errors = [];
        window.fbq('track', 'Lead');
      }
    }
  }
}

</script>
