<template>
  <div class="divide-y divide-gray-200">
    <div class="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
      <div class="flex items-center space-x-4">
        <div class="flex flex-col">
          <label class="block text-sm leading-loose font-medium text-gray-600">Point de départ</label>
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <input type="text" v-model="origin" v-mask="'S#S #S#'"  class="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 placeholder-gray-300" placeholder="ex. J3X 1W1">
            <div class="absolute left-3 top-2">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <label class="block text-sm leading-loose font-medium text-gray-600">Point d'arrivé</label>
          <div class="relative focus-within:text-gray-600 text-gray-400">
            <input v-model="destiny"  v-mask="'S#S #S#'" type="text" class="pr-4 pl-10 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 placeholder-gray-300" placeholder="ex. H0H 0H0">
            <div class="absolute left-3 top-2">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
            </div>
          </div>
        </div>
      </div>
      
      <div class="flex flex-col">
        <label for="price" class="block text-sm leading-loose font-medium text-gray-600">Poids total</label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input type="text" name="price" id="price" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full px-4 py-2 sm:text-sm border-gray-300 rounded-md placeholder-gray-300" placeholder="ex. 0" v-model="weight">
          <div class="absolute inset-y-0 right-0 flex items-center">
            <label for="currency" class="sr-only">Unit</label>
            <select id="currency" name="currency" class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md" v-model="weight_units">
              <option v-for="option in units" v-bind:value="option.value" v-bind:key="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <label class="block text-sm leading-loose font-medium text-gray-600">Type de colis</label>
        <select class="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" v-model="send_type">
          <option v-for="option in types" v-bind:value="option.value" v-bind:key="option.value">
            {{ option.text }}
          </option>
        </select>
      </div>

    </div>
    <div class="pt-4 flex items-center space-x-2">
        <button class="flex text-sm lg:text-base justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none" @click="clear">
          <svg class="w-5 h-5 sm:w-6 sm:h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Effacer
        </button>
        <button class="bg-gradient-to-r from-red-700 to-red-600 text-sm lg:text-base flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none" @click="submit">
          <svg v-if="spinning" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <svg v-if="!spinning" class="w-5 h-5 sm:w-6 sm:h-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" /></svg> 
          Soumission
        </button>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

export default {
  name: 'EstimateForm',
  data: function () {
    return {
      spinning: false,
      origin: '',
      destiny: '',
      weight: '',
      weight_units: 'lb',
      send_type: 'CustomerPackaging',
      types: [
        { text: 'Expressᴹᴰ Boîte', value: 'ExpressBox' },
        { text: 'Expressᴹᴰ Enveloppe', value: 'ExpressEnvelope' },
        { text: 'Expressᴹᴰ Pack', value: 'ExpressPack' },
        { text: 'Emballage du client', value: 'CustomerPackaging' }
      ],
      units: [
        { text: 'kg', value: 'kg' },
        { text: 'lbs', value: 'lb' },
      ]
    }
  },
  methods: {
    clear: function() {
      this.origin = '';
      this.destiny = '';
      this.weight = '2';
      this.weight_units = 'lb';
      this.send_type = 'CustomerPackaging';
    },
    submit: function() {

      if(this.origin != '' && this.destiny != '' && this.weight != '') {
          this.$ga.event('Quote', 'start', 'Debut de soumission')

          this.spinning = true
          axios
          .post('/php/service.php', {
          //.post('http://puro.test/test.php', {
            origin_postal_code: this.origin,
            destination_postal_code: this.destiny,
            package_type: this.send_type,
            weight: this.weight,
            weight_unit: this.weight_units
          })
          .then( response => {
            this.$emit("response", response.data);
            if (response.data.Error == undefined) {
              this.clear();
            } 
          })
      } else {
        this.$ga.event('Quote', 'validation-error', 'Erreur de validation')
        this.spinning = false;
        this.$emit("response", {Error: [{'AdditionalInformation': 'Erreur du formulaire', 'Description': 'Tous les champs sont obligatoires.'}]});
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
