<template>
  <div class="min-h-screen bg-white">
      <router-view></router-view>
  </div>
</template>

<script> 

export default {
  name: 'app'
}
</script>

<style>

</style>
