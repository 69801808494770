import Vue from 'vue'
import App from './App.vue'
import './assets/styles/tailwind.css';
import VueTheMask from 'vue-the-mask'
import VueAnalytics from 'vue-analytics';
import VueRouter from 'vue-router'

import LandingCalculator from './components/LandingCalculator.vue'
import LandingPromo from './components/LandingPromo.vue'
import LandingPromo100 from './components/LandingPromo100.vue'
import LandingNewCalculator from './components/LandingNewCalculator.vue'
import ContactPage from './components/ContactPage.vue'

Vue.use(VueTheMask)
Vue.use(VueRouter)

Vue.use(VueAnalytics, {
  id: 'UA-165233785-3'
});

Vue.config.productionTip = false

const routes = [
  { path: '/', component: LandingNewCalculator },
  { path: '/promo', component: LandingPromo },
  { path: '/promo100', component: LandingPromo100 },
  { path: '/calculator', component: LandingCalculator },
  { path: '/contact', component: ContactPage }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
