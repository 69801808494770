<template>
  <div class="relative bg-white py-6 sm:py-8">
    <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start justify-items-center content-center">
      <div class="relative x-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
        <!-- Content area -->
        <div class="pt-12 sm:pt-16 lg:pt-28 text-center">
          <div class="sm:text-center lg:text-left md:pl-12 pl-2">
            <h1 class="text-4xl tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Expédiez vos <span class="font-semibold">colis</span> aux <span class="block font-semibold xl:inline">meilleurs prix </span>
            </h1>
            <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Groupe Alliances vous offre des économies sur vos envois partout au Canada avec son service Purolator
              <!-- <img class="h-6 mt-6 inline" src="/images/Puro logo.png" alt="Tuple"> -->
            </p>
            <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div class="rounded-md shadow">
                <router-link class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10" to="/contact">Essayez gratuitement</router-link>
              </div>
              <!-- <div class="mt-3 sm:mt-0 sm:ml-3">
                <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 md:py-4 md:text-lg md:px-10">
                  Calcul d'économies
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="relative sm:py-5 lg:py-0">
        <div class="bg-white sm:max-w-lg sm:w-full sm:mx-auto rounded-3xl sm:overflow-hidden border-solid border-2 border-light-gray-100">
          <Calculateur></Calculateur>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Calculateur from './Calculateur.vue'
export default {
  name: 'PromoHero',
  components: {
    Calculateur
  },
}
</script>